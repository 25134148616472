import {
  LISTA_PESQUISAS,
  ATUALIZA_PESQUISAS,
  PESQUISA_SUCCESS,
  PESQUISA_ERROR,
  CONTATO_SUCCESS,
  CONTATO_ERROR,
  INSERIR_PESQUISA,
  DUPLICAR_PESQUISA,
  ORDENAR_PESQUISA,
  INSERIR_PERGUNTA,
  ATUALIZA_ENVIO_PESQUISA,
  ALTERAR_PESQUISA,
  INSERIR_PESQUISA_RESULT,
  INSERIR_PAGINA,
  ALTERAR_PERGUNTA,
  INSERIR_CONFIGURACAO_EMAIL,
  INSERIR_CONFIGURACAO_WIDGET,
  ATUALIZA_CONFIGURACAO_WIDGET,
  ALTERAR_DATA_PAGINA_AGRADECIMENTO,
  INSERIR_WIDGET_STATE,
  ALTERAR_CONFIGURACAO_EMAIL,
  RESULTADO_CONFIGURACAO_EMAIL,
  INSERIR_LISTA,
  INSERIR_LISTA_ARQUIVO,
  INSERIR_LISTA_MANUAL,
  ALTERAR_LISTA,
  ATUALIZA_LISTA_ENVIO,
  INSERIR_LISTA_RESULT,
  ATUALIZA_LISTA_INDIVIDUAL,
  PROCESSAR_LISTA,
  PROCESSAR_LISTA_RESULT,
  ALTERAR_PAGINA_AGRADECIMENTO,
  ALTERAR_DATA_PAGINA_AGRADECIMENTO_RESULT,
  BUSCA_LISTA,
  BUSCA_LISTA_RESULT,
  INSERIR_LISTA_ENVIO,
  ATUALIZAR_TOTAL_CONTATOS_ENVIO,
  ALTERAR_AGRADECIMENTO_RESULT,
  CRIA_QUESTIONARIO,
  ENVIA_EMAIL_TESTE,
  FINALIZA_EMAIL_TESTE,
  INSERIR_PERSONALIZAR_LAYOUT,
  ALTERAR_PERSONALIZAR_LAYOUT,
  IMPORTA_QUESTIONARIO_MODELO,
  REMOVE_LISTA_CONTATO,
  ATIVA_LOADER,
  DESATIVA_LOADER,
  ATIVA_LOADER_CONTATOS,
  DESATIVA_LOADER_CONTATOS,
  ATUALIZAR_PERGUNTA,
  ATUALIZA_PAGINAS,
  ATUALIZAR_TODAS_PERGUNTAS,
  ATUALIZA_PESQUISA_INDIVIDUAL,
  ATUALIZA_LAYOUT_PERSONALIZADO,
  ATUALIZA_CONFIGURACAO_EMAIL,
  BUSCA_PESQUISA_INDIVIDUAL,
  INSERIR_ENVIO,
  ATUALIZA_NOME_ENVIO,
  AGENDA_ENVIO_RELATORIO,
  ALTERA_INTERVALO_ENVIO_API,
  ENVIO_RESULT_SUCCESS,
  FINALIZA_PESQUISA_API,
  ALTERA_PERFIL_GERENCIAMENTO,
  ALTERA_SENHA_USUARIO,
  INSERIR_TRADUCAO_PERGUNTA,
  INSERIR_TRADUCAO_CONVITE,
  CLEAR_STATE,
  INSERIR_TRADUCAO_PAGINA_AGRADECIMENTO,
  UPDATE_IDIOMA,
  INSERIR_IDIOMA,
  REMOVE_IDIOMA_PESQUISA,
  ALTERAR_IDIOMA_PESQUISA,
  PERGUNTA_REMOVIDA,
  REMOVER_LIMITE_RESPOSTA,
  UPDATE_LIMITE_RESPOSTA,
  NOVO_LIMITE_RESPOSTA,
  INSERT_LIMITE_RESPOSTA,
  INSERT_TEMPO_OCIOSIDADE,
  UPDATE_ARRAY_LIMITE_RESPOSTA,
  UPDATE_TEMPO_REINICIA_TOTEM,
  UPDATE_CONFIGURACAO_QUARENTENA,
  RESULTADO_CONFIGURACAO_QUARENTENA,
  ATUALIZA_CONFIGURACAO_QUARENTENA

} from './actionTypes';

export const limpaEstado = () => ({
  type: CLEAR_STATE,
});

export const buscaLista = () => ({
  type: BUSCA_LISTA,
});

export const buscaListaResult = (payload) => ({
  type: BUSCA_LISTA_RESULT,
  payload,
});

export const listaPesquisas = () => ({
  type: LISTA_PESQUISAS,
});

export const buscaPesquisaIndividual = (payload) => ({
  type: BUSCA_PESQUISA_INDIVIDUAL,
  payload,
});
export const atualizaPesquisas = (payload) => ({
  type: ATUALIZA_PESQUISAS,
  payload,
});

export const atualizaPesquisaIndividual = (payload) => ({
  type: ATUALIZA_PESQUISA_INDIVIDUAL,
  payload,
});

export const inserirPesquisa = (payload) => ({
  type: INSERIR_PESQUISA,
  payload,
});
export const duplicarPesquisa = (payload) => ({
  type: DUPLICAR_PESQUISA,
  payload,
});

export const alterarPesquisa = (payload) => ({
  type: ALTERAR_PESQUISA,
  payload,
});

export const criaQuestionario = (payload) => ({
  type: CRIA_QUESTIONARIO,
  payload,
});

export const sucessoPesquisa = () => ({
  type: PESQUISA_SUCCESS,
});

export const erroPesquisa = (payload) => ({
  type: PESQUISA_ERROR,
  payload,
});

export const sucessoContato = () => ({
  type: CONTATO_SUCCESS,
});

export const erroContato = (payload) => ({
  type: CONTATO_ERROR,
  payload,
});

export const inserirPesquisaResult = (pesquisas, payload) => ({
  type: INSERIR_PESQUISA_RESULT,
  pesquisas,
  payload,
});

export const ordenarPesquisa = (payload) => ({
  type: ORDENAR_PESQUISA,
  payload,
});

export const inserirIdioma = (payload) => ({
  type: INSERIR_IDIOMA,
  payload,
});

export const atualizarPergunta = (payload) => ({
  type: ATUALIZAR_PERGUNTA,
  payload,
});

export const atualizaTodasPerguntas = (payload) => ({
  type: ATUALIZAR_TODAS_PERGUNTAS,
  payload,
});

export const inserirPergunta = (payload) => ({
  type: INSERIR_PERGUNTA,
  payload,
});

export const inserirPagina = (payload) => ({
  type: INSERIR_PAGINA,
  payload,
});

export const atualizaPagina = (payload) => ({
  type: ATUALIZA_PAGINAS,
  payload,
});

export const alterarPergunta = (payload) => ({
  type: ALTERAR_PERGUNTA,
  payload,
});

export const inserirConfiguracaoEmail = (payload) => ({
  type: INSERIR_CONFIGURACAO_EMAIL,
  payload,
});
export const inserirConfiguracaoWidgetAction = (payload) => ({
  type: INSERIR_CONFIGURACAO_WIDGET,
  payload,
});
export const atualizaConfiguracaoWidgetAction = (payload) => ({
  type: ATUALIZA_CONFIGURACAO_WIDGET,
  payload,
});

export const inserirWidgetState = (payload) => ({
  type: INSERIR_WIDGET_STATE,
  payload,
});

export const inserirEnvio = (payload) => ({
  type: INSERIR_ENVIO,
  payload,
});

export const alterarConfiguracaoEmail = (payload) => ({
  type: ALTERAR_CONFIGURACAO_EMAIL,
  payload,
});

export const resultadoConfiguracaoEmail = (payload) => ({
  type: RESULTADO_CONFIGURACAO_EMAIL,
  payload,
});

export const atualizaConfiguracaoEmail = (payload) => ({
  type: ATUALIZA_CONFIGURACAO_EMAIL,
  payload,
});

export const inserirLista = (payload) => ({
  type: INSERIR_LISTA,
  payload,
});

export const atualizaListaIndividual = (payload) => ({
  type: ATUALIZA_LISTA_INDIVIDUAL,
  payload,
});

export const removeListaContato = (payload) => ({
  type: REMOVE_LISTA_CONTATO,
  payload,
});

export const inserirListaArquivo = (payload) => ({
  type: INSERIR_LISTA_ARQUIVO,
  payload,
});

export const processarLista = (payload) => ({
  type: PROCESSAR_LISTA,
  payload,
});

export const processarListaResult = (payload) => ({
  type: PROCESSAR_LISTA_RESULT,
  payload,
});

export const inserirListaResult = (payload) => ({
  type: INSERIR_LISTA_RESULT,
  payload,
});

export const alterarLista = (payload) => ({
  type: ALTERAR_LISTA,
  payload,
});

export const adicionarListaManual = (payload) => ({
  type: INSERIR_LISTA_MANUAL,
  payload,
});

export const alterarPaginaAgradecimento = (payload) => ({
  type: ALTERAR_PAGINA_AGRADECIMENTO,
  payload,
});

export const alterarDataAgradecimento = (payload) => ({
  type: ALTERAR_DATA_PAGINA_AGRADECIMENTO,
  payload,
});

export const inserirListaEnvio = (payload) => ({
  type: INSERIR_LISTA_ENVIO,
  payload,
});

export const atualizarTotalContatosEnvio = (payload) => ({
  type: ATUALIZAR_TOTAL_CONTATOS_ENVIO,
  payload,
});

export const alterarAgradecimentoResult = (payload) => ({
  type: ALTERAR_AGRADECIMENTO_RESULT,
  payload,
});

export const alterarDataAgradecimentoResult = (payload) => ({
  type: ALTERAR_DATA_PAGINA_AGRADECIMENTO_RESULT,
  payload,
});

export const sendEmailTeste = (payload) => ({
  type: ENVIA_EMAIL_TESTE,
  payload,
});

export const finalizaEmailTeste = (payload) => ({
  type: FINALIZA_EMAIL_TESTE,
  payload,
});

export const inserirPersonalizarLayout = (payload) => ({
  type: INSERIR_PERSONALIZAR_LAYOUT,
  payload,
});

export const alterarPersonalizarLayout = (payload) => ({
  type: ALTERAR_PERSONALIZAR_LAYOUT,
  payload,
});

export const atualizaLayoutPersonalizado = (payload) => ({
  type: ATUALIZA_LAYOUT_PERSONALIZADO,
  payload,
});

export const importaQuestionarioModelo = (payload) => ({
  type: IMPORTA_QUESTIONARIO_MODELO,
  payload,
});

export const atualizarEnvioPesquisa = (payload) => ({
  type: ATUALIZA_ENVIO_PESQUISA,
  payload,
});
export const alteraNomeEnvio = (payload) => ({
  type: ATUALIZA_NOME_ENVIO,
  payload,
});
export const atualizaListaEnvio = (payload) => ({
  type: ATUALIZA_LISTA_ENVIO,
  payload,
});

export const agendaEnvioRelatorio = (payload) => ({
  type: AGENDA_ENVIO_RELATORIO,
  payload,
});

export const alteraIntervaloEnvioApi = (payload) => ({
  type: ALTERA_INTERVALO_ENVIO_API,
  payload,
});

export const resultEnvio = (payload) => ({
  type: ENVIO_RESULT_SUCCESS,
  payload,
});

export const finalizaPesquisaApi = (payload) => ({
  type: FINALIZA_PESQUISA_API,
  payload,
});

export const alteraPerfilGerenciamento = (payload) => ({
  type: ALTERA_PERFIL_GERENCIAMENTO,
  payload,
});

export const alteraSenhaUsuario = (payload) => ({
  type: ALTERA_SENHA_USUARIO,
  payload,
});

export const ativaLoader = () => ({
  type: ATIVA_LOADER,
});

export const desativaLoader = () => ({
  type: DESATIVA_LOADER,
});

export const ativaLoaderContatos = () => ({
  type: ATIVA_LOADER_CONTATOS,
});

export const desativaLoaderContatos = () => ({
  type: DESATIVA_LOADER_CONTATOS,
});

export const inserirTraducaoPergunta = (payload) => ({
  type: INSERIR_TRADUCAO_PERGUNTA,
  payload,
});
export const inserirTraducaoPaginaAgradecimento = (payload) => ({
  type: INSERIR_TRADUCAO_PAGINA_AGRADECIMENTO,
  payload,
});

export const inserirTraducaoConvite = (payload) => ({
  type: INSERIR_TRADUCAO_CONVITE,
  payload,
});

export const updateIdioma = (payload) => ({
  type: UPDATE_IDIOMA,
  payload,
});

export const removeIdiomaPesquisa = (payload) => ({
  type: REMOVE_IDIOMA_PESQUISA,
  payload,
});

export const alterarIdiomaPadraoPesquisa = (payload) => ({
  type: ALTERAR_IDIOMA_PESQUISA,
  payload,
});

export const perguntaQuestionarioRemovida = (payload) => ({
  type: PERGUNTA_REMOVIDA,
  payload,
});

export const removeLimiteRespostaPesquisa = (payload) => ({
  type: REMOVER_LIMITE_RESPOSTA,
  payload,
});

export const updateLimiteResposta = (payload) => ({
  type: UPDATE_LIMITE_RESPOSTA,
  payload,
});

export const atualizaNovoLimiteRespostaPesquisa = (payload) => ({
  type: NOVO_LIMITE_RESPOSTA,
  payload,
});

export const insertLimiteResposta = (payload) => ({
  type: INSERT_LIMITE_RESPOSTA,
  payload,
});

export const insertTempoOciosidade = (payload) => ({
  type: INSERT_TEMPO_OCIOSIDADE,
  payload,
});

export const updateArrayLimiteResposta = (payload) => ({
  type: UPDATE_ARRAY_LIMITE_RESPOSTA,
  payload,
})

export const updateTempoReiniciaTotem = (payload) => ({
  type: UPDATE_TEMPO_REINICIA_TOTEM,
  payload,
})

export const updateconfiguracaoQuarentena = (payload) => ({
  type: UPDATE_CONFIGURACAO_QUARENTENA,
  payload,
})

export const resultadoConfiguracaoQuarentena = (payload) => ({
  type: RESULTADO_CONFIGURACAO_QUARENTENA,
  payload,
});

export const atualizaConfiguracaoQuarentena = (payload) => ({
  type: ATUALIZA_CONFIGURACAO_QUARENTENA,
  payload,
});
