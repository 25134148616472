import React from 'react';

import Loader from 'components/Loader';
import { Outlet } from 'react-router-dom';

// const ResponderRefactor = React.lazy(
//   () =>
//     import(
//       /* webpackChunkName: "ResponderRefactor" */ 'pages/ResponderRefactor'
//     ),
// );

const ResponderV3 = React.lazy(
  () => import(/* webpackChunkName: "ResponderV3" */ 'pages/ResponderV3'),
);

export const rotasResponderLinkRefactor = {
  path: '/l',
  element: (
    <React.Suspense fallback={<Loader />}>
      <Outlet />
    </React.Suspense>
  ),
  children: [
    {
      path: ':link_token',
      element: <ResponderV3 />,
    },
  ],
};
