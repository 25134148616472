export default class AcoesContato {
  constructor(contatos) {
    this.contatos = contatos.length > 0 ? contatos : [];
  }

  setContatos(contatos) {
    this.contatos = contatos.sort((prev, next) => prev.id - next.id);
  }

  addContatos(novoContato) {
    if (this.contatos.length > 0) {
      const { contatos } = this;
      contatos.push(novoContato);
      this.setContatos(contatos);

      return this.contatos;
    }

    const contatos = [novoContato];

    this.setContatos(contatos);

    return this.contatos;
  }

  atualizaContatos(novoContato) {
    const contatos = this.contatos.map((contatosItem) => {
      if (contatosItem.id === novoContato.id) {
        return {
          ...contatosItem,
          ...novoContato,
        };
      }
      return contatosItem;
    });

    this.setContatos(contatos);

    return this.contatos;
  }

  addOuAtualizaContatos(novoContato) {
    const contatoExiste = this.contatos.find(
      (contatosItem) => contatosItem.id === novoContato.id,
    );

    if (contatoExiste) {
      return this.atualizaContatos(novoContato);
    }
    return this.addContatos(novoContato);
  }

  removeListaContato(listaId) {
    const contatos = this.contatos.filter(
      (contatoItem) => contatoItem.id !== listaId,
    );

    this.setContatos(contatos);

    return this.contatos;
  }
}
