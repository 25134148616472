import React from 'react';

import { permissaoTodosUsuariosExcetoVisualizador } from 'config/Roles';
// import ContatosLayout from 'components/layouts/ContatosLayout';
import { PrivateRoute } from './PrivateRoute';

const ContatosLayout = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Contatos/ContatosLayout" */ 'components/layouts/ContatosLayout'
    ),
);

const Contatos = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Contatos/Listagem" */ 'pages/Contatos/Listagem'
    ),
);
const CriarListaManual = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Contatos/CriarListaManual" */ 'pages/Contatos/CriarListaManual'
    ),
);
const PreviewLista = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Contatos/PreviewLista" */ 'pages/Contatos/PreviewLista'
    ),
);
const MapearLista = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Contatos/MapearLista" */ 'pages/Contatos/MapearLista'
    ),
);
const ProcessandoLista = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Contatos/ProcessandoLista" */ 'pages/Contatos/ProcessandoLista'
    ),
);
const MapearListaConfirmaçao = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Contatos/MapearListaConfirmaçao" */ 'pages/Contatos/MapearListaConfirmaçao'
    ),
);
const Relatorio = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Contatos/Relatorio" */ 'pages/Contatos/Relatorio'
    ),
);
const CriarListaImportada = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Contatos/CriarListaImportada" */ 'pages/Contatos/CriarListaImportada'
    ),
);

export const rotasContato = (HomeLayout: React.FC) => {
  return {
    path: '/contato',
    element: (
      <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
        <HomeLayout />
      </PrivateRoute>
    ),
    children: [
      {
        index: true,
        element: <Contatos />,
      },
      {
        path: 'lista/:listaId/importar/manual',
        element: (
          <ContatosLayout proximaPagina="preview">
            <CriarListaManual />
          </ContatosLayout>
        ),
      },
      {
        path: 'lista/:listaId/preview',
        element: (
          <ContatosLayout proximaPagina="mapear">
            <PreviewLista />
          </ContatosLayout>
        ),
      },
      {
        path: 'lista/:listaId/mapear',
        element: (
          <ContatosLayout proximaPagina="confirmacao">
            <MapearLista />
          </ContatosLayout>
        ),
      },
      {
        path: 'lista/:listaId/confirmacao',
        element: (
          <ContatosLayout>
            <MapearListaConfirmaçao />
          </ContatosLayout>
        ),
      },
      {
        path: 'lista/:listaId/processando',
        element: (
          <ContatosLayout proximaPagina="lista-processando">
            <ProcessandoLista />
          </ContatosLayout>
        ),
      },
      {
        path: 'lista/:listaId/relatorio',
        element: <Relatorio />,
      },
      {
        path: 'lista/:listaId/importar/arquivo',
        element: (
          <ContatosLayout proximaPagina="preview">
            <CriarListaImportada />
          </ContatosLayout>
        ),
      },
    ],
  };
};
