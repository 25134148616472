/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { BrowserRouter, useRoutes } from 'react-router-dom';
import { Provider } from 'react-redux';
import Loader from 'components/Loader';

import { PersistedStateProvider } from 'contexts/PersistedState';
import { rotasPesquisa } from './pesquisa';
import { rotasResponder } from './responder';
import { rotasResponderRefactor } from './responderRefactor';
import { rotasContato } from './contato';
import { rotasAdminSistema } from './adminSistema';
import { rotasOrganizacao } from './organizacao';
import { rotasAlterarSenha } from './alterarSenha';
import { rotasBenchmark } from './benchmark';
import { rotasPreviewQuestionario } from './previewQuestionario';
import { rotasPreviewWidget } from './previewWidget';
import { rotasResponderLinkRefactor } from './responderLinkRefactor';
import { rotasResponderV3 } from './responderV3';
import { rotasResponderV3Link } from './responderV3Link';
import { rotasCloseTheLoop } from './closeTheLoop';
import { rotasCategorizacao } from './categorizacao';
import { rotasOptout } from './optout';
import { rotasQuarentena } from './quarentena';

const HomeLayout = React.lazy(
  () =>
    import(
      /* webpackChunkName: "HomeLayout" */ 'components/layouts/HomeLayout'
    ),
);

const Login = React.lazy(
  () => import(/* webpackChunkName: "Login" */ 'pages/Login'),
);

const SelecionarOrganizacao = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SelecionarOrganizacao" */ 'pages/SelecionarOrganizacao'
    ),
);

type AppRouterProps = {
  store: any;
};

function Routes() {
  const routes = useRoutes([
    rotasPesquisa(HomeLayout),
    rotasOrganizacao(HomeLayout),
    rotasResponder,
    rotasResponderRefactor,
    rotasResponderLinkRefactor,
    rotasResponderV3,
    rotasResponderV3Link,
    rotasContato(HomeLayout),
    rotasAdminSistema(HomeLayout),
    rotasAlterarSenha,
    rotasBenchmark(HomeLayout),
    rotasPreviewQuestionario,
    rotasPreviewWidget,
    rotasCloseTheLoop(HomeLayout),
    rotasCategorizacao(HomeLayout),
    rotasOptout,
    rotasQuarentena(HomeLayout),
    {
      path: '*',
      element: (
        <React.Suspense fallback={<Loader />}>
          <Login />
        </React.Suspense>
      ),
    },
    {
      path: '/selecionar-organizacao',
      element: (
        <React.Suspense fallback={<Loader />}>
          <SelecionarOrganizacao />
        </React.Suspense>
      ),
    },
  ]);
  return routes;
}

function AppRouter({ store }: AppRouterProps): JSX.Element {
  return (
    <main className="d-flex h-100">
      <BrowserRouter>
        <PersistedStateProvider>
          <Provider store={store}>
            <Routes />
          </Provider>
        </PersistedStateProvider>
      </BrowserRouter>
    </main>
  );
}

export default AppRouter;
