import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Pesquisa, Listas, ContatoResult } from '../types';

interface PesquisaObj {
  contato: {
    error?: string;
    items: Listas[];
    loading: boolean;
    result?: ContatoResult;
  };
  form: string | Record<string, undefined>;
  pesquisa: {
    error?: string;
    items: Pesquisa[];
    loading: boolean;
  };
}

const useContatos = (): Listas[] => {
  const contatos = useSelector((store: PesquisaObj) => store.contato.items);
  const [contatosState, setContatosState] = useState(contatos);

  useEffect(() => {
    setContatosState(contatos);
  }, [contatos]);

  return contatosState;
};

export { useContatos };
