import instance from './instance';
import { Lista_filtro } from '../../entities/contato/lista_filtro';

interface Filtro extends Lista_filtro {
  nome_coluna_bd: string;
  nome_personalizado: string;
  organizacao_id: number;
  tipo: number;
}

interface FiltrosLista {
  data: Filtro[];
}

const buscaListaFiltro = (
  id?: string | undefined | number,
): Promise<FiltrosLista> => instance.get(`/lista/filtros/${id}`);

export default buscaListaFiltro;
