import React from 'react';
import { Outlet } from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';

const PreviewQuestionario = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PreviewQuestionario" */ '../pages/PreviewQuestionario'
    ),
);

export const rotasPreviewQuestionario = {
  path: '/preview-questionario/',
  element: (
    <PrivateRoute>
      <Outlet />
    </PrivateRoute>
  ),
  children: [
    ...[':token', 'l/:link_token'].map((path) => {
      return {
        path,
        element: <PreviewQuestionario />,
      };
    }),
  ],
};
