import React from 'react';

import { permissaoTodosUsuariosExcetoVisualizador } from 'config/Roles';

import Loader from 'components/Loader';
import { PrivateRoute } from './PrivateRoute';

const Quarentena = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "Pesquisas" */ 'pages/Quarentena/pages/ListagemQuarentena'
    ),
);

const RespondenteQuarentena = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "Pesquisas" */ 'pages/Quarentena/pages/RespondenteQuarentena'
    ),
);

export const rotasQuarentena = (HomeLayout: React.FC) => {
  return {
    path: '/quarentena',
    element: (
      <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
        <React.Suspense fallback={<Loader />}>
          <HomeLayout />
        </React.Suspense>
      </PrivateRoute>
    ),
    children: [
      {
        index: true,
        element: <Quarentena />,
      },
      {
        path: ':respondenteId/',
        element: (
          <PrivateRoute permission={permissaoTodosUsuariosExcetoVisualizador}>
            <RespondenteQuarentena />
          </PrivateRoute>
        ),
      },
    ],
  };
};
